<script>
import Layout from "./../../../layouts/main";
import appConfig from "../../../../app.config";

export default {
  page: {
    meta: [{ name: "Binance integration", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  name: "FAQs",
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
      <div class="row">
            <div class="offset-lg-3 col-lg-6 mb-5">
              <h1 class="text-center">Пошаговая инструкция Регистрация на бирже Бинанс и Подтверждение Личности</h1>
            </div>
            <div class="col-lg-12">
                <!-- end card -->
                <h2>Регистрация на бирже Бинанс</h2>
                <div class="row justify-content-evenly">
                  <ol>
                    <li>
                      Перейдите по ссылке:
                      <a href="https://www.binance.com/en/activity/referral-entry/CPA?fromActivityPage=true&ref=CPA_00KSM5JZ31">
                        https://www.binance.com/ru
                      </a>
                      .
                      <br>
                      <i><b>Процесс регистрации на бирже может занять от 1 до 3 рабочих дней.</b></i>
                    </li>
                  </ol>
                </div>
            </div><!--end col-->.
        </div><!--end row-->
    </Layout>
</template>
